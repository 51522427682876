import { createAsync } from '@solidjs/router';
import { For, Match, Show, Suspense, Switch } from 'solid-js';
import {
	ActivityIndicator,
	Button,
	Link,
	Picture,
	ProductCard,
	Tag,
	TroonAccessPlusProduct,
	TroonAccessProduct,
} from '@troon/ui';
import { Icon } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { getAccessProducts } from '../providers/card';
import { useUser } from '../providers/user';
import { TroonCardSubscriptionProductType } from '../graphql';
import { usePersisted } from '../providers/persistence-store';
import { createIsExperimentBranch } from '../providers/faux-experiment';
import type { JSXElement } from 'solid-js';

type Props = {
	banners?: Record<TroonCardSubscriptionProductType, JSXElement>;
	floating?: boolean;
	redirect?: string;
};

export function AccessProducts(props: Props) {
	const showGolfpass = createIsExperimentBranch('golfpass+', 'show golfpass+');
	const [params] = usePersisted();
	const data = createAsync(() => getAccessProducts({ promoCode: params.promoCode?.value }), { deferStream: true });
	const user = useUser();
	const trackEvent = useTrackEvent();

	return (
		<Suspense fallback={<ActivityIndicator />}>
			<Show when={data()?.products}>
				{(products) => (
					<ul class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6">
						<For each={products()}>
							{(card) => (
								<li class="shrink grow">
									<ProductCard
										banner={props.banners && props.banners[card.type]}
										isCurrent={user()?.me?.troonAccessProductType === card.type}
										floating={props.floating}
										variant={card.type === TroonCardSubscriptionProductType.TroonAccessPlus ? 'plus' : undefined}
									>
										<div class="flex flex-col gap-4">
											<div class="flex flex-row items-center justify-between">
												<h2 class="text-xl font-semibold">
													<Show
														when={card.type === TroonCardSubscriptionProductType.TroonAccessPlus}
														fallback={<TroonAccessProduct class="h-8" />}
													>
														<TroonAccessPlusProduct class="h-8" current />
													</Show>
													<span class="sr-only">{card.subscriptionName}</span>
												</h2>
												<Show when={user()?.me?.troonAccessProductType === card.type}>
													<Tag>Current Plan</Tag>
												</Show>
											</div>
											<Show when={card.description}>
												<p>{card.description}</p>
											</Show>
										</div>
										<p class="mb-2 text-3xl font-semibold">
											<Show when={card.subtotalAmount.value !== card.totalAmount.value}>
												<span class="me-2 text-5xl font-normal text-neutral-700 line-through">
													{card.subtotalAmount.displayValue}
												</span>
											</Show>
											<span class="text-5xl">{card.totalAmount.displayValue}</span>
											<Show when={card.autoRenew && !card.disclosure}>/yr</Show>
											<Show when={card.disclosure}>
												{(disclosure) => <span class="block pt-2 text-sm font-normal">{disclosure()}</span>}
											</Show>
										</p>

										<div class="flex flex-col gap-4 border-t border-neutral pt-8">
											<p>
												<Show
													when={card.type === TroonCardSubscriptionProductType.TroonAccessPlus}
													fallback={<>Includes:</>}
												>
													<>Everything in Access tier plus:</>
												</Show>
											</p>
											<ul class="flex flex-col gap-3">
												<For
													each={
														card.type === TroonCardSubscriptionProductType.TroonAccessPlus
															? card.additionalValueProps
															: card.valueProps
													}
												>
													{(value) => (
														<li class="flex flex-row items-center gap-2">
															<Icon name="check" class="shrink-0 text-xl text-brand" />
															{value}
														</li>
													)}
												</For>
												<Show when={showGolfpass && card.type === TroonCardSubscriptionProductType.TroonAccessPlus}>
													<li class="relative mt-3 rounded border border-brand-100/20 bg-brand-100/20 px-3 py-2">
														<Picture
															src={golfpassLogo}
															alt="GolfPass+"
															sizes={[[246, 41]]}
															width={123}
															height={21}
															class="relative -top-2.5 -mb-4"
														/>
														One year of GolfPass+ for free! (over $200 value)
													</li>
												</Show>
											</ul>
										</div>
										<div class="flex h-full grow flex-col justify-end">
											<div>
												<Switch>
													<Match when={user()?.me.troonAccessProductType === card.type}>
														<Button as={Link} href="/account/access" appearance="secondary-current">
															Manage Plan
														</Button>
													</Match>
													<Match when={!user()?.me.troonAccessProductType}>
														<Button
															as={Link}
															rel="nofollow"
															href={`/access/checkout/${card.id}`}
															state={{ redirect: props.redirect }}
															appearance={card.isPopular ? 'primary' : 'secondary'}
															onClick={() => {
																trackEvent('didSelectAccessProduct', {
																	productType: 'troonAccess',
																	productId: card.id,
																});
															}}
														>
															<span>
																Select<span class="sr-only"> the {card.subscriptionName}</span> and Continue
															</span>{' '}
															<Icon name="arrow-right-md" />
														</Button>
													</Match>
												</Switch>
											</div>
										</div>
									</ProductCard>
								</li>
							)}
						</For>
					</ul>
				)}
			</Show>
		</Suspense>
	);
}

const golfpassLogo =
	'https://images.ctfassets.net/rdsy7xf5c8dt/39shrMUGcPa9lWDXsfZP7i/ed6d8f4d50f0f368a42722c1a9604bbc/golf-pass-plus-logo.png';
